import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 720.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,720.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M5080 6573 c-428 -30 -687 -97 -1041 -269 -253 -123 -587 -360 -546
-386 7 -5 3 -8 -11 -8 -28 0 -135 -98 -128 -116 3 -9 -1 -11 -11 -7 -12 5 -15
2 -10 -10 4 -11 2 -14 -7 -11 -16 6 -40 -21 -31 -36 3 -5 0 -10 -8 -10 -13 0
-97 -82 -97 -94 0 -4 -19 -24 -41 -46 -23 -22 -39 -40 -35 -40 3 0 -18 -34
-47 -75 -28 -41 -73 -107 -98 -147 -65 -103 -229 -442 -245 -508 0 -3 -3 -12
-7 -20 -12 -33 -37 -109 -58 -180 -12 -41 -24 -80 -26 -85 -2 -6 11 -19 28
-29 43 -25 54 -12 84 105 82 324 239 639 463 929 89 116 336 360 452 446 355
264 714 420 1130 491 140 24 180 27 415 27 219 0 281 -3 395 -22 74 -11 144
-24 155 -26 133 -35 180 -48 235 -64 257 -78 579 -252 807 -436 79 -64 275
-252 328 -316 94 -112 175 -218 175 -229 0 -4 8 -15 18 -24 9 -9 26 -33 37
-54 11 -21 22 -40 25 -43 22 -19 145 -264 175 -345 10 -27 22 -54 25 -60 12
-17 76 -226 107 -344 19 -75 80 -67 75 10 -2 24 -7 48 -12 53 -6 6 -10 18 -10
27 0 14 -21 92 -38 139 -44 126 -102 271 -112 280 -3 3 -27 50 -54 105 -79
161 -265 433 -374 548 -152 160 -242 242 -379 344 -358 266 -755 439 -1153
502 -120 19 -409 43 -474 39 -17 -1 -51 -3 -76 -5z m-1820 -897 c0 -2 -8 -10
-17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z"/>
<path d="M4875 6365 c-187 -27 -451 -95 -560 -145 -11 -5 -48 -20 -82 -34
-139 -57 -362 -185 -428 -245 -19 -17 -39 -31 -44 -31 -5 0 -20 -9 -33 -20
-12 -11 -41 -34 -63 -52 -53 -43 -157 -141 -209 -198 -22 -24 -58 -60 -78 -79
-21 -19 -38 -38 -38 -42 0 -7 -126 -178 -139 -189 -3 -3 -11 -14 -18 -25 -6
-11 -39 -65 -71 -120 -33 -55 -68 -118 -78 -140 -11 -22 -21 -42 -24 -45 -8
-7 -85 -194 -83 -200 1 -3 -2 -9 -6 -15 -5 -5 -16 -37 -26 -70 -10 -33 -22
-65 -27 -71 -5 -7 -8 -16 -7 -20 1 -5 -2 -20 -8 -34 -24 -61 -24 -77 3 -95 14
-9 32 -14 39 -11 8 3 26 45 40 93 69 230 148 411 257 591 112 184 204 300 357
452 142 141 232 215 381 312 64 42 275 159 293 162 7 2 35 13 62 25 288 130
676 202 1010 187 195 -8 355 -30 494 -67 182 -49 366 -119 511 -196 281 -149
506 -333 721 -588 19 -22 37 -42 40 -45 4 -3 45 -61 91 -130 152 -227 299
-554 334 -743 9 -51 47 -72 77 -42 18 17 15 32 -39 192 -47 141 -64 181 -126
308 -137 275 -279 476 -483 680 -94 95 -289 256 -345 285 -14 7 -54 32 -90 55
-241 157 -601 292 -920 345 -108 18 -572 22 -685 5z"/>
<path d="M5183 6078 c-5 -7 -15 -26 -23 -42 -15 -29 -45 -46 -83 -46 -49 0
-52 -16 -14 -61 35 -40 36 -45 31 -94 -8 -69 7 -83 78 -67 38 9 59 9 89 1 54
-17 72 -1 63 56 -5 34 -1 52 20 94 15 28 24 56 21 61 -4 6 -21 10 -38 10 -58
0 -76 11 -98 56 -22 46 -31 52 -46 32z"/>
<path d="M4660 5960 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M5697 5945 c-10 -14 -28 -25 -40 -25 -39 0 -50 -14 -27 -35 14 -13
20 -31 20 -61 0 -32 4 -44 18 -48 15 -4 100 -9 129 -7 6 1 13 22 16 49 3 26 7
58 9 71 2 20 -2 25 -27 28 -16 2 -39 15 -50 28 -25 30 -26 30 -48 0z"/>
<path d="M4648 5933 c-10 -2 -18 -11 -18 -18 0 -9 -6 -12 -17 -8 -12 5 -14 3
-8 -8 8 -12 6 -12 -8 0 -25 20 -34 5 -12 -19 12 -13 18 -34 17 -59 -1 -30 3
-41 16 -45 32 -9 122 -10 131 0 5 5 11 27 14 49 3 22 8 50 12 62 7 21 4 22
-28 21 -21 -1 -42 6 -52 15 -17 18 -18 18 -47 10z"/>
<path d="M4351 5800 c-17 -17 -34 -44 -37 -58 -19 -85 -27 -102 -58 -130 -44
-38 -101 -49 -151 -28 -66 28 -149 14 -185 -29 -14 -17 -14 -17 10 -4 14 8 28
18 31 22 13 17 85 19 123 3 109 -46 212 11 236 131 9 39 21 66 42 86 16 16 27
31 25 34 -3 2 -19 -10 -36 -27z"/>
<path d="M6028 5796 c20 -23 34 -54 42 -90 24 -117 133 -180 226 -131 40 20
77 19 125 -5 57 -29 79 -71 79 -147 0 -130 53 -187 174 -189 75 -1 117 -16
151 -54 22 -24 24 -78 10 -213 l-7 -67 43 -44 c34 -34 56 -46 97 -55 70 -15
89 -26 112 -64 30 -49 26 -113 -11 -171 -16 -27 -28 -51 -25 -54 7 -6 4 -10
44 52 70 109 20 228 -108 255 -68 15 -87 26 -110 64 -24 39 -26 108 -5 168 18
51 7 98 -34 142 -39 43 -58 50 -153 57 -74 5 -92 10 -114 31 -35 33 -46 63
-45 132 1 69 -15 112 -55 149 -46 42 -93 50 -168 28 -129 -37 -185 2 -220 152
-7 29 -52 88 -68 88 -6 0 4 -15 20 -34z"/>
<path d="M3889 5508 c-7 -12 -13 -54 -14 -93 0 -43 -7 -82 -18 -101 -21 -40
-97 -78 -138 -69 -44 10 -102 -3 -135 -31 l-29 -24 49 24 c40 19 60 23 100 19
68 -7 117 14 155 68 28 38 31 49 31 116 0 41 5 83 10 94 6 10 8 19 6 19 -3 0
-11 -10 -17 -22z"/>
<path d="M3532 5141 c-16 -46 -15 -83 3 -117 37 -71 1 -163 -72 -189 -24 -8
-63 -22 -89 -31 -53 -19 -93 -71 -101 -133 -5 -33 1 -51 33 -108 20 -37 39
-62 41 -56 2 6 -11 32 -28 57 -27 38 -33 55 -32 96 0 27 8 61 16 74 22 33 74
63 127 71 90 15 153 114 125 199 -23 70 -27 104 -15 126 6 12 10 26 7 33 -2 6
-9 -4 -15 -22z"/>
<path d="M4997 5045 c-163 -33 -353 -115 -475 -206 -63 -48 -188 -171 -221
-218 -39 -54 -9 -32 47 35 28 34 77 84 109 112 61 53 159 122 173 122 5 0 10
3 12 8 9 20 206 101 284 117 288 58 546 28 774 -92 151 -80 290 -193 375 -307
16 -21 31 -34 33 -28 4 12 -25 47 -113 137 -166 169 -375 278 -613 320 -105
18 -292 18 -385 0z"/>
<path d="M5080 4959 c-154 -18 -318 -78 -449 -163 -70 -45 -236 -195 -226
-204 2 -3 44 33 93 79 132 127 288 210 479 255 117 28 337 26 458 -5 106 -27
286 -112 368 -175 69 -52 167 -144 167 -157 0 -5 5 -9 10 -9 22 0 7 21 -59 85
-86 84 -173 146 -276 195 -175 83 -388 121 -565 99z"/>
<path d="M5061 4860 c-179 -25 -351 -108 -501 -239 -19 -17 -30 -31 -24 -31 6
0 31 17 55 39 278 244 694 292 1029 119 72 -37 111 -63 199 -136 23 -19 41
-29 41 -23 0 12 -129 115 -181 145 -70 40 -186 89 -259 107 -87 23 -265 32
-359 19z"/>
<path d="M3675 4242 c43 -9 45 -18 44 -181 -1 -206 -7 -243 -39 -252 -23 -5
-21 -7 19 -18 24 -7 51 -12 60 -11 9 0 65 3 126 5 91 4 112 8 128 24 14 14 18
27 13 45 -7 30 -29 34 -48 8 -22 -32 -54 -42 -131 -42 l-74 0 5 83 c5 108 20
120 109 92 17 -5 25 -3 29 10 10 25 -13 35 -88 35 -64 0 -95 12 -63 24 11 4
15 22 15 74 0 96 11 101 173 80 10 -1 15 -8 13 -15 -3 -7 -1 -13 3 -13 5 0 7
-9 4 -21 -5 -17 -2 -20 13 -17 13 2 20 13 22 36 5 47 -24 56 -203 57 -82 1
-141 -1 -130 -3z"/>
<path d="M6200 4226 c0 -18 39 -31 52 -18 15 15 0 32 -28 32 -14 0 -24 -6 -24
-14z"/>
<path d="M4150 4090 c-18 -18 -27 -35 -24 -45 8 -20 17 -19 34 6 8 12 21 28
28 35 17 18 83 18 106 1 22 -16 38 -91 23 -106 -6 -6 -39 -11 -73 -11 -70 0
-119 -26 -143 -75 -12 -24 -12 -32 2 -54 39 -67 176 -81 177 -17 0 4 -8 5 -17
0 -42 -18 -88 -16 -107 5 -17 19 -18 24 -5 58 16 42 48 62 106 67 58 5 63 0
63 -66 0 -75 12 -98 50 -98 29 0 41 15 18 22 -7 3 -10 31 -9 87 4 152 -42 221
-147 221 -43 0 -56 -5 -82 -30z"/>
<path d="M5317 4094 c-34 -23 -40 -24 -50 -10 -7 9 -28 18 -47 21 -29 5 -31 4
-12 -4 30 -14 31 -17 34 -158 3 -114 2 -123 -17 -132 -19 -8 -18 -10 10 -21
21 -8 39 -8 60 -2 27 9 28 11 9 17 -20 6 -20 8 -7 133 11 107 17 130 34 145
12 9 28 17 36 17 27 0 71 -30 76 -52 12 -46 10 -232 -2 -236 -35 -12 21 -35
60 -25 22 6 26 23 5 23 -10 0 -13 10 -10 38 2 20 6 66 9 102 3 36 7 76 9 90 2
14 5 28 5 32 1 3 17 12 36 18 35 11 70 2 87 -24 4 -6 8 -61 8 -122 0 -130 13
-159 65 -152 l30 4 -29 12 c-23 10 -26 15 -16 27 18 22 8 181 -14 223 -33 64
-133 79 -175 27 -23 -30 -35 -31 -54 -5 -14 20 -56 40 -85 40 -10 0 -34 -12
-55 -26z"/>
<path d="M5867 4102 c-28 -18 -43 -45 -32 -62 11 -18 23 -11 44 26 21 36 23
36 72 32 61 -6 80 -27 76 -83 l-2 -40 -50 -3 c-81 -4 -111 -11 -138 -35 -82
-70 1 -177 112 -147 24 6 41 17 41 25 0 18 -12 19 -27 4 -6 -6 -28 -11 -48
-12 -40 -1 -66 21 -60 51 14 71 14 71 63 86 89 26 120 4 110 -79 -2 -19 0 -35
4 -35 4 0 8 -7 8 -15 0 -18 28 -34 51 -28 21 6 26 23 7 23 -9 0 -14 28 -16 98
-4 130 -15 168 -55 192 -41 25 -121 26 -160 2z"/>
<path d="M6495 4107 c-17 -8 -36 -21 -44 -30 -17 -21 -31 -22 -31 -2 0 9 -12
20 -26 25 -32 12 -57 12 -27 0 21 -9 22 -16 25 -145 3 -126 2 -137 -17 -149
-17 -12 -15 -13 25 -20 43 -7 77 7 55 22 -13 9 -2 205 13 242 16 40 50 54 96
38 51 -17 64 -53 65 -178 1 -60 5 -100 13 -108 15 -15 54 -16 63 -2 3 6 -2 10
-12 10 -16 0 -18 10 -18 108 0 86 -4 113 -18 135 -36 55 -107 79 -162 54z"/>
<path d="M4502 4095 c-39 -17 -50 -41 -29 -62 14 -14 17 -12 32 16 19 37 49
51 111 51 65 0 64 -24 -8 -130 -64 -94 -126 -160 -152 -160 -16 0 -17 -3 -8
-12 9 -9 50 -12 144 -10 139 2 168 12 168 54 0 23 -9 23 -36 -3 -23 -22 -126
-38 -160 -25 -15 6 -15 8 -2 24 8 9 18 24 21 33 3 9 35 57 71 106 36 50 66 96
66 102 0 6 10 15 23 20 14 5 -19 9 -93 9 -79 1 -125 -3 -148 -13z"/>
<path d="M4823 4092 c15 -10 24 -21 21 -24 -3 -4 3 -26 14 -51 11 -25 23 -59
27 -76 4 -17 10 -29 15 -26 4 3 6 -5 2 -17 -3 -13 -1 -19 5 -15 7 5 9 0 6 -11
-3 -12 8 -32 31 -56 l35 -38 -16 -41 c-19 -48 -38 -66 -71 -67 -16 0 -27 9
-36 30 -9 23 -15 27 -24 18 -7 -7 -12 -17 -12 -23 0 -17 31 -41 60 -48 33 -8
102 27 123 63 9 15 43 106 76 203 32 97 62 181 66 186 4 7 -15 11 -57 10 -46
0 -57 -3 -42 -9 12 -5 25 -13 28 -18 8 -13 -28 -149 -56 -211 -32 -71 -54 -67
-76 14 -33 122 -64 192 -92 208 -38 23 -62 22 -27 -1z"/>
<path d="M6180 4097 c24 -12 25 -16 28 -127 3 -135 0 -155 -30 -165 -36 -11
58 -27 96 -16 30 8 30 9 8 18 -22 8 -23 14 -25 135 -2 143 -13 168 -71 168
l-31 0 25 -13z"/>
<path d="M2663 3422 c-13 -2 -23 -5 -23 -7 0 -16 44 -208 55 -244 60 -179 179
-442 255 -561 16 -25 35 -54 42 -66 172 -278 451 -566 729 -752 80 -53 227
-142 236 -142 3 0 17 -8 31 -18 36 -25 290 -138 332 -147 8 -2 42 -13 75 -25
347 -123 810 -163 1195 -105 185 28 433 96 595 162 360 148 643 339 905 609
143 149 164 173 285 341 172 238 363 685 381 889 l7 71 -34 -1 c-33 -1 -34 -3
-47 -56 -16 -72 -61 -227 -77 -265 -102 -250 -172 -387 -274 -540 -39 -58 -71
-108 -71 -111 0 -16 -125 -159 -239 -274 -225 -225 -441 -381 -706 -510 -383
-187 -779 -271 -1205 -257 -305 11 -577 67 -872 181 -61 23 -274 128 -323 159
-11 6 -51 31 -88 55 -195 121 -458 358 -609 546 -212 264 -366 554 -453 856
-20 68 -39 129 -43 134 -3 6 -7 21 -8 35 -4 31 -23 48 -51 43z"/>
<path d="M2854 3423 l-21 -4 22 -93 c75 -307 219 -592 457 -901 151 -195 412
-421 638 -552 462 -267 918 -377 1423 -343 173 12 273 27 432 66 535 133 1028
461 1358 905 217 291 411 732 398 904 -1 15 -9 21 -30 23 -24 2 -30 -3 -36
-25 -5 -16 -9 -32 -11 -38 -6 -18 -25 -89 -34 -125 -30 -116 -186 -436 -283
-580 -32 -47 -63 -94 -70 -104 -6 -11 -31 -41 -54 -66 -24 -26 -43 -51 -43
-56 0 -5 -8 -16 -17 -25 -10 -8 -35 -34 -55 -58 -48 -55 -220 -205 -333 -291
-174 -131 -361 -231 -585 -314 -596 -219 -1233 -191 -1825 79 -109 50 -343
197 -456 285 -86 68 -292 276 -371 375 -108 135 -94 115 -177 250 -66 107
-171 319 -171 345 0 5 -6 24 -14 42 -8 18 -31 87 -52 153 -39 126 -55 153 -90
148z"/>
<path d="M3337 3385 c-2 -27 -16 -62 -32 -85 -40 -57 -45 -125 -14 -180 26
-45 73 -80 110 -80 44 -1 107 -37 125 -72 19 -37 22 -105 5 -136 -6 -12 -11
-43 -11 -70 0 -99 85 -168 193 -156 69 7 114 -17 145 -76 17 -35 21 -54 16
-91 -17 -124 94 -215 212 -175 65 22 100 20 149 -9 43 -25 63 -57 70 -114 10
-78 78 -136 159 -136 42 0 60 6 101 34 90 63 180 40 230 -59 42 -82 94 -112
178 -102 28 3 51 16 80 44 73 70 87 78 134 78 54 0 75 -10 133 -65 54 -52 107
-70 165 -54 50 14 80 41 111 101 27 53 52 74 107 89 39 10 60 3 157 -51 84
-48 219 21 220 112 1 42 36 104 74 127 40 25 83 25 167 1 41 -12 52 -12 93 4
28 11 57 32 73 53 24 31 28 46 30 114 4 135 40 173 166 175 97 2 143 28 177
100 21 47 22 50 6 106 -36 130 -3 205 100 223 140 25 196 144 123 260 -17 28
-34 67 -37 87 -2 21 -9 38 -15 38 -19 0 1 -80 33 -127 28 -43 31 -54 28 -105
-6 -83 -30 -106 -145 -137 -61 -17 -100 -54 -117 -112 -13 -43 -13 -54 1 -95
10 -29 14 -64 10 -97 -4 -42 -10 -56 -41 -84 -36 -33 -51 -37 -136 -34 -55 2
-115 -30 -147 -78 -18 -27 -23 -48 -23 -101 0 -92 -18 -133 -73 -159 -52 -25
-80 -26 -138 -5 -60 21 -120 11 -164 -28 -32 -28 -44 -53 -60 -132 -9 -45 -63
-92 -113 -101 -47 -8 -83 3 -141 42 -43 29 -101 30 -157 2 -34 -16 -48 -31
-69 -74 -34 -74 -73 -100 -149 -100 -56 0 -60 2 -95 41 -20 23 -52 51 -71 63
-65 39 -154 21 -199 -40 -32 -44 -101 -72 -152 -64 -42 7 -109 62 -109 90 0
20 -48 73 -81 90 -47 25 -101 19 -177 -21 -61 -32 -74 -35 -113 -29 -63 11
-103 55 -119 130 -10 48 -20 67 -50 96 -50 48 -94 56 -181 33 -63 -16 -70 -16
-111 -1 -63 24 -89 74 -86 160 3 55 -1 69 -25 105 -38 59 -89 82 -178 82 -70
0 -72 1 -110 39 -33 33 -39 45 -39 82 0 24 7 66 15 93 17 56 9 106 -26 150
-26 32 -58 50 -125 65 -75 18 -107 55 -112 128 -3 52 0 63 28 106 33 48 50
120 32 131 -5 3 -12 -13 -15 -39z"/>
<path d="M4296 3243 c80 -125 254 -266 424 -345 408 -190 892 -117 1225 184
85 77 171 175 161 185 -3 3 -18 -10 -33 -28 -117 -143 -251 -247 -417 -324
-277 -129 -622 -134 -899 -13 -152 67 -351 217 -432 327 -34 45 -57 56 -29 14z"/>
<path d="M4400 3261 c0 -15 159 -158 219 -198 128 -85 266 -136 456 -169 64
-10 203 -5 294 12 178 33 310 91 453 197 71 52 173 155 164 164 -3 3 -31 -22
-63 -54 -117 -119 -271 -210 -445 -264 -99 -31 -110 -33 -278 -33 -138 0 -189
3 -240 17 -198 55 -344 136 -478 266 -74 72 -82 78 -82 62z"/>
<path d="M4556 3239 c43 -46 139 -112 222 -154 139 -69 257 -98 407 -97 207 1
363 47 529 157 80 52 160 125 139 125 -6 0 -17 -7 -24 -16 -22 -27 -121 -99
-179 -132 -136 -76 -283 -113 -450 -114 -232 -1 -425 67 -598 212 -67 56 -91
66 -46 19z"/>
<path d="M4420 1042 c0 -157 0 -159 24 -171 41 -23 46 -9 46 131 0 150 -11
198 -47 198 l-23 0 0 -158z"/>
<path d="M5870 1041 c0 -167 5 -187 47 -179 16 3 18 16 18 143 -1 169 -6 195
-40 195 l-25 0 0 -159z"/>
<path d="M3574 1168 c-4 -7 -9 -65 -12 -129 -3 -111 -3 -117 18 -132 15 -10
30 -13 44 -8 21 8 29 30 41 106 6 34 8 37 17 21 6 -10 8 -37 6 -61 -4 -33 -1
-44 13 -53 25 -16 69 -15 70 1 0 6 2 21 5 32 7 34 -16 121 -38 145 -17 18 -28
21 -58 17 -37 -5 -38 -4 -44 34 -5 33 -10 39 -31 39 -13 0 -27 -5 -31 -12z"/>
<path d="M3815 1172 c-39 -13 -15 -52 33 -52 24 0 25 3 16 38 -6 23 -14 25
-49 14z"/>
<path d="M5317 1173 c-4 -3 -7 -21 -7 -39 l0 -34 -45 7 c-39 5 -49 2 -70 -17
-49 -46 -42 -126 17 -175 27 -23 35 -25 101 -18 40 3 76 11 80 17 10 14 -1
180 -15 229 -8 28 -16 37 -33 37 -12 0 -25 -3 -28 -7z m-13 -149 c9 -22 -11
-54 -34 -54 -25 0 -27 32 -4 54 20 20 30 20 38 0z"/>
<path d="M5420 1157 c0 -25 16 -37 51 -37 16 0 20 6 17 28 -2 22 -8 28 -35 30
-29 3 -33 0 -33 -21z"/>
<path d="M6784 1168 c-4 -6 -9 -62 -12 -124 -3 -88 -1 -116 10 -127 22 -22 41
-25 75 -11 20 8 35 9 38 3 4 -5 22 -9 41 -9 40 0 58 26 30 42 -10 6 -24 24
-31 41 -11 26 -11 36 0 57 7 14 16 35 19 48 7 20 4 22 -28 22 -25 0 -38 -6
-46 -20 -16 -30 -30 -24 -30 13 0 55 -10 77 -36 77 -12 0 -26 -5 -30 -12z"/>
<path d="M7366 1173 c-15 -15 -4 -43 19 -49 39 -10 56 1 46 28 -8 22 -50 35
-65 21z"/>
<path d="M2733 1150 c-86 -52 -82 -175 9 -235 46 -31 95 -32 148 -5 36 19 40
24 40 60 0 21 -5 51 -12 65 -10 22 -17 25 -63 25 -43 0 -54 -3 -58 -19 -5 -21
9 -41 31 -41 7 0 12 -6 10 -12 -7 -21 -59 -15 -74 7 -12 18 -11 26 4 55 15 31
22 35 58 36 56 1 74 10 74 34 0 45 -110 65 -167 30z"/>
<path d="M4668 1162 c-14 -3 -17 -20 -20 -120 -2 -95 0 -119 12 -129 32 -26
80 -9 80 28 0 24 18 33 35 19 18 -15 42 1 55 35 7 15 9 8 9 -27 1 -36 6 -50
22 -62 30 -20 66 -3 71 35 6 38 -16 207 -28 218 -5 5 -23 7 -40 4 -22 -3 -37
-15 -55 -44 -13 -21 -27 -39 -30 -39 -4 0 -18 17 -32 39 -25 38 -47 50 -79 43z"/>
<path d="M6123 1163 l-33 -4 0 -118 c0 -113 1 -118 24 -130 37 -20 58 -10 70
33 10 35 13 37 25 22 18 -21 26 -20 51 6 l20 22 0 -33 c0 -51 42 -81 78 -55
19 13 20 22 16 87 -9 120 -22 176 -42 172 -9 -2 -26 -4 -37 -4 -12 -1 -29 -16
-43 -41 -12 -22 -26 -40 -30 -40 -4 0 -20 20 -35 45 -16 25 -30 44 -30 43 -1
0 -17 -3 -34 -5z"/>
<path d="M7210 1135 c0 -14 -4 -25 -9 -25 -16 0 -21 -44 -6 -53 10 -5 15 -25
15 -63 0 -47 4 -59 26 -79 29 -27 54 -31 85 -15 37 20 15 82 -26 72 -16 -4
-18 -2 -7 9 6 7 12 20 12 28 0 8 7 22 15 32 24 25 10 69 -22 69 -13 0 -22 4
-19 9 12 19 -7 41 -35 41 -24 0 -29 -4 -29 -25z"/>
<path d="M2958 1098 c-2 -7 -5 -50 -7 -95 -2 -74 0 -83 19 -93 43 -23 64 -10
75 47 6 31 18 58 29 66 15 11 17 21 12 50 -7 38 -22 46 -45 25 -11 -10 -14
-10 -18 0 -3 6 -17 12 -33 12 -15 0 -30 -6 -32 -12z"/>
<path d="M3128 1103 c-41 -11 -10 -53 38 -53 18 0 36 -4 39 -10 4 -6 -10 -10
-34 -10 -78 0 -104 -65 -46 -116 18 -16 36 -22 77 -22 86 0 98 9 98 68 0 61
-19 111 -51 134 -22 15 -81 20 -121 9z m77 -132 c-3 -6 -14 -11 -23 -11 -13 0
-13 2 4 14 21 15 30 14 19 -3z"/>
<path d="M3320 985 c0 -113 2 -125 19 -135 33 -17 61 -12 73 15 8 17 19 25 37
25 37 0 89 35 97 65 17 69 -38 155 -100 155 -17 0 -38 -6 -45 -12 -11 -10 -14
-10 -18 0 -3 6 -18 12 -34 12 l-29 0 0 -125z m140 25 c0 -23 -5 -31 -25 -36
-34 -8 -37 -4 -23 32 15 41 48 43 48 4z"/>
<path d="M3798 1018 c-3 -101 3 -118 45 -118 32 0 51 23 43 55 -9 34 6 32 27
-4 35 -59 122 -75 163 -30 22 24 25 24 53 -2 12 -12 40 -23 61 -26 32 -5 43
-1 64 20 31 31 32 42 7 84 -13 20 -31 34 -53 39 l-33 8 40 6 c31 4 41 10 43
26 2 17 -4 23 -29 28 -61 12 -87 7 -108 -21 -32 -39 -13 -81 45 -103 36 -14
23 -25 -14 -12 -23 8 -33 7 -45 -5 -14 -14 -17 -14 -33 1 -13 13 -26 16 -55
11 -43 -7 -50 5 -28 47 8 16 17 19 40 14 23 -4 31 -1 40 16 28 52 -84 80 -137
34 -15 -13 -33 -41 -40 -62 l-12 -39 -1 37 c-2 62 -16 88 -50 88 l-30 0 -3
-92z"/>
<path d="M4984 1092 c-56 -47 -55 -110 2 -167 38 -38 78 -45 124 -22 19 10 25
21 24 42 0 17 4 33 9 36 18 13 -3 77 -34 103 -36 31 -93 34 -125 8z m86 -52
c0 -5 -15 -10 -32 -10 -25 0 -29 3 -18 10 20 13 50 13 50 0z m20 -59 c0 -12
-41 -22 -54 -13 -6 3 0 9 14 13 36 10 40 10 40 0z"/>
<path d="M5420 1011 c0 -81 3 -100 16 -105 28 -11 63 -6 72 10 8 14 12 13 43
-5 27 -15 47 -19 92 -15 32 2 63 6 68 9 14 9 11 112 -6 150 -15 38 -46 55 -98
55 -53 0 -76 -10 -70 -28 9 -24 24 -32 60 -32 17 0 35 -4 38 -10 4 -6 -11 -10
-39 -10 -36 0 -48 -5 -64 -25 -27 -34 -32 -32 -32 18 0 73 -8 87 -46 87 l-34
0 0 -99z m210 -36 c0 -9 -9 -15 -22 -15 -21 0 -21 0 -2 15 24 18 24 18 24 0z"/>
<path d="M6423 1103 c-7 -2 -13 -13 -13 -23 0 -14 10 -21 38 -25 61 -11 70
-19 22 -23 -53 -4 -80 -25 -80 -62 0 -42 39 -72 102 -75 104 -6 121 13 100
115 -15 73 -46 100 -110 99 -26 0 -53 -3 -59 -6z m82 -132 c-3 -6 -16 -11 -28
-11 -21 1 -21 1 3 15 27 14 36 13 25 -4z"/>
<path d="M6620 1017 c0 -98 7 -117 45 -117 30 0 45 19 45 57 0 24 8 41 26 58
22 21 25 30 20 60 -7 37 -15 41 -46 25 -13 -7 -21 -7 -25 0 -3 5 -19 10 -36
10 l-29 0 0 -93z"/>
<path d="M7014 1092 c-34 -27 -48 -83 -31 -125 16 -36 71 -77 105 -77 48 0 95
41 78 69 -3 5 0 14 8 20 20 17 0 76 -37 107 -34 29 -92 32 -123 6z m86 -52 c0
-5 -15 -10 -32 -9 -28 0 -30 2 -13 9 28 12 45 12 45 0z m2 -71 c-15 -6 -30 -5
-40 1 -13 7 -8 9 23 9 35 0 37 -1 17 -10z"/>
<path d="M7360 1017 c0 -101 7 -117 51 -117 19 0 30 6 34 20 9 27 4 117 -9
158 -8 28 -14 32 -43 32 l-33 0 0 -93z"/>
<path d="M7470 1017 c0 -101 7 -117 51 -117 30 0 39 19 39 81 0 20 7 42 15 49
13 11 15 5 15 -48 0 -50 4 -63 21 -75 27 -19 58 -10 70 20 13 34 -15 143 -42
166 -25 20 -69 22 -88 5 -11 -10 -14 -10 -18 0 -3 6 -18 12 -34 12 l-29 0 0
-93z"/>
<path d="M7715 1084 c-25 -27 -30 -58 -19 -101 9 -32 68 -67 100 -59 18 5 24
3 18 -5 -4 -7 -23 -9 -55 -6 -60 7 -75 -15 -34 -50 38 -33 123 -32 162 1 26
23 28 30 28 98 0 40 -4 90 -8 111 -7 32 -12 37 -37 37 -15 0 -30 -6 -33 -12
-4 -10 -7 -10 -18 0 -23 22 -78 15 -104 -14z m110 -65 c0 -19 -6 -25 -27 -27
-32 -4 -35 4 -12 36 19 28 39 24 39 -9z"/>
<path d="M4513 750 c-40 -24 -53 -48 -53 -97 0 -73 60 -133 132 -133 41 0 82
20 86 42 4 22 -36 44 -51 29 -15 -15 -70 -14 -85 1 -17 17 -15 74 5 93 26 26
62 36 84 24 15 -7 24 -7 35 2 21 17 17 27 -16 44 -40 21 -99 19 -137 -5z"/>
<path d="M4754 710 c-53 -21 -72 -106 -34 -149 27 -32 86 -51 123 -40 55 16
76 65 52 122 -25 60 -87 89 -141 67z m77 -64 c15 -32 -4 -66 -36 -66 -21 0
-25 5 -25 29 0 29 22 61 41 61 5 0 14 -11 20 -24z"/>
<path d="M4943 713 c-20 -8 -18 -167 3 -184 27 -22 49 1 52 57 3 51 15 74 38
74 9 0 14 -18 16 -61 2 -46 8 -65 21 -73 30 -19 50 4 54 64 3 51 16 73 38 67
6 -2 12 -30 15 -62 5 -62 22 -84 55 -71 15 5 17 15 12 67 -9 98 -55 145 -121
121 -25 -9 -41 -9 -65 -1 -25 9 -39 9 -59 -1 -19 -8 -28 -8 -30 -1 -4 12 -9
12 -29 4z"/>
<path d="M5289 710 c-12 -7 -16 -31 -18 -119 -2 -103 -1 -110 20 -125 27 -18
54 -7 62 26 5 18 14 23 44 25 56 3 87 42 80 100 -8 74 -69 117 -130 94 -17 -7
-27 -7 -27 -1 0 12 -10 12 -31 0z m121 -86 c0 -36 -20 -55 -52 -46 -16 4 -19
11 -14 36 12 62 66 70 66 10z"/>
<path d="M5523 710 c-37 -15 -18 -39 35 -42 57 -4 71 -28 15 -28 -76 0 -106
-48 -58 -95 21 -22 33 -25 90 -25 76 0 86 11 75 85 -14 93 -79 136 -157 105z
m85 -122 c-3 -7 -18 -14 -34 -16 l-29 -2 25 14 c31 19 43 20 38 4z"/>
<path d="M5724 712 c-7 -4 -13 -43 -15 -89 -4 -69 -1 -83 13 -92 42 -26 58 -9
58 62 1 49 19 80 40 67 10 -6 15 -27 15 -62 0 -55 14 -77 47 -78 43 0 34 126
-12 172 -30 30 -53 34 -94 18 -17 -6 -26 -6 -26 0 0 12 -11 13 -26 2z"/>
<path d="M5927 712 c-21 -4 -19 -13 23 -114 24 -57 26 -68 14 -78 -7 -7 -20
-10 -28 -7 -8 3 -20 -1 -26 -9 -10 -11 -8 -18 11 -34 54 -45 106 -11 144 94
13 38 27 89 31 114 6 40 5 44 -10 39 -10 -4 -21 -7 -25 -7 -5 0 -18 -24 -30
-52 l-23 -53 -24 50 c-29 58 -33 63 -57 57z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
